import React, { useEffect } from "react";

export const Header = (props) => {
  useEffect(() => {
    // Function to handle smooth scrolling to the target with a top margin
    const handleScroll = (event) => {
      event.preventDefault();

      // Get the target element based on the href attribute
      const targetId = event.target.getAttribute("href").substring(1);
      console.log(targetId);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        // Calculate the target position with a top margin of 65px
        const targetPosition = targetElement.offsetTop - 65;

        // Scroll smoothly to the target position
        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    };

    // Attach the handleScroll function to all anchor links with the "page-scroll" class
    const links = document.querySelectorAll(".page-scroll");
    links.forEach((link) => {
      link.addEventListener("click", handleScroll);
    });

    // Clean up the event listeners on component unmount
    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleScroll);
      });
    };
  }, []);
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <div className="buttonscontainer">
                  <a href="#services" className="btn-secondary page-scroll">
                    View Services
                  </a>
                  {"    "}
                  <a
                    href="#contact"
                    className="btn btn-custom btn-lg page-scroll"
                  >
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
