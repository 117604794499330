import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../main_logo.svg";
export const Navigation = (props) => {
  useEffect(() => {
    // Function to handle smooth scrolling to the target with a top margin
    const handleScroll = (event) => {
      event.preventDefault();

      // Get the target element based on the href attribute
      const targetId = event.target.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        // Calculate the target position with a top margin of 65px
        const targetPosition = targetElement.offsetTop - 65;

        // Scroll smoothly to the target position
        window.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    };

    // Attach the handleScroll function to all anchor links with the "page-scroll" class
    const links = document.querySelectorAll(".page-scroll");
    links.forEach((link) => {
      link.addEventListener("click", handleScroll);
    });

    // Clean up the event listeners on component unmount
    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleScroll);
      });
    };
  }, []);
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {/* <a className="navbar-brand page-scroll" href="#page-top">
            React Landing Page
          </a>{" "} */}
          <Logo
            // className="navbar-brand page-scroll"
            className="navbar-brand page-scroll"
            alt="Bitseamless Logo"
            href="#header"
          />{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li> */}
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
