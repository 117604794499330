import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

export const Contact = (props) => {
  // const [{ firstname, lastname, email, message }, setState] =
  //   useState(initialState);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [phone, setPhone] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstname":
        setFirstname(value);
        break;
      case "lastname":
        setLastname(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const clearState = () => {
    setFirstname("");
    setLastname("");
    setPhone("");
    setEmail("");
    setMessage("");
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    // console.log(firstname, lastname, phone, email, message);

    emailjs
      .sendForm(
        "service_2uov0ig",
        "template_0ftsinb",
        e.target,
        "XQeieZDioZHZ-Airb"
      )
      .then(
        (result) => {
          // console.log(result.text);

          toast.success("Message successfully sent!");
          clearState();
          setIsLoading(false);
          // Scroll to the element with the ID "header"
          // const headerElement = document.getElementById("header");
          // if (headerElement) {
          //   window.scrollTo({
          //     top: headerElement.offsetTop,
          //     behavior: "smooth",
          //   });
          // }
        },
        (error) => {
          console.log(error.text);
          toast.error("Oops! Something went wrong.");
          setIsLoading(false);
        }
      );
  };
  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        className="form-control"
                        placeholder="First Name"
                        required
                        onChange={handleChange}
                        value={firstname}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={handleChange}
                        value={lastname}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {/* <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        required
                        pattern="[0-9+-]*"
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div> */}
                    <div className="form-group">
                      <PhoneInput
                        defaultCountry="CA"
                        international
                        className="form-control"
                        placeholder="Phone Number"
                        id="phone"
                        name="phone"
                        value={phone}
                        required
                        onChange={setPhone}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    style={{ resize: "none" }}
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg"
                  disabled={isLoading}
                >
                  {isLoading ? "Please wait..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? (
                  <a className="contact-email" href={`tel:${props.data.phone}`}>
                    {props.data.phoneDisplay}
                  </a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? (
                  <a
                    className="contact-email"
                    href={`mailto:${props.data.email}`}
                  >
                    {props.data.email}
                  </a>
                ) : (
                  "loading"
                )}
              </p>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {new Date().getFullYear()} Bitseamless.ca | All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
